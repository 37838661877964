<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-row class="">
      <v-col v-for="(article, idx) in articles" :key="idx" cols="12" :sm="idx == 0 ? 12 : 6" :md="idx == 0 ? 12 : 4">
        <v-card :to="{name: 'blogPost', params: { id: article.slug }}">
          <v-img v-if="article.thumbnail"
            :src="idx == 0 ? article.image : article.thumbnail"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
            height="300px"
          >
            <v-card-title class="display-1" v-text="article.title"></v-card-title>
          </v-img>
          <v-card-title v-else class="display-2" v-text="article.title"></v-card-title>

          <v-card-text>
            <span v-if="article.created" class="black--text">{{ article.created }}</span>
            <span v-if="article.date" class="black--text">{{ article.date | localDate('ddd L') }}</span>
            <span v-if="idx>0 && article.description" class="black--text">• {{ article.description }}</span>
            <span v-if="idx>0 && article.summary" class="black--text">• {{ article.summary }}</span>
          </v-card-text>
          <v-card-text v-if="idx==0">
            <!-- <p>{{ article.summary }}</p> -->
            <p v-if="article.description">{{ article.description }}</p>
            <p v-else>{{ article.summary }}</p>

            <v-btn color="primary" outlined>Read full post</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import articleService from "@/services/articleService";
import cmsService from "@/services/cmsService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "CaseStudies",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      articles: null,
    };
  },
  created() {
  },
  async mounted() {
    //this.articles = await cmsService.getCollectionByCategory('case-studies');
    this.articles = await cmsService.getCollectionByCategory('Case Studies');

    this.$nextTick(() => {
      // when content is loaded
    }); 

  },
  methods: {
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Case Studies', exact: true, disabled: true},
      ];
    },
  },
};
</script>

<style scoped>
</style>